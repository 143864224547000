import { useRef, Suspense, useMemo, useEffect } from "react";
import { Box, Flex } from "@storyofams/react-ui";
import { AnimatePresence } from "framer-motion";
import qs from "query-string";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router";

import { Button, Container, Layout, LazyLoad, Seo, Subtext, Title } from "~/components";
import config from "~/config";
import { FlowProvider, HeadlessProvider, SessionProvider } from "~/context";
import { ActiveFlowContainerQuery, FlowNodeLayout, FlowNodeType, PreviewFlowContainerQuery } from "~/graphql/api/sdk";
import { useDetectKeyboard, useIsMobile, useSdk } from "~/hooks";

import { messages } from "./messages";
import { Router } from "./Router";
import { CustomCSS } from "~/components/CustomCSS";

const hideLoader = () => {
  const root = document.querySelector("#perfect-product-finder");

  if (root) {
    (root as any).style.opacity = 1;
  }

  const pageLoader = document.querySelector("#page-loader");

  if (pageLoader) {
    (pageLoader as any).style.opacity = 0;

    setTimeout(() => {
      (pageLoader as any).style.display = "none";
    }, 120);
  }
};

export const App = ({ history, location }: RouteComponentProps) => {
  const isMobile = useIsMobile();
  const sdk = useSdk();

  const initRef = useRef(false);

  useEffect(() => {
    if (window.self === window.top) {
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
    }
  }, []);

  const slug = window.location.pathname.match(new RegExp(`\\/.*\\/.*\\/(.*)\\/?.*$`, "i"))?.[1];

  useEffect(() => {
    const sendHeightToParent = () => {
      const height = document.body.scrollHeight;
      if (height) {
        window?.parent?.postMessage(
          {
            action: "resize",
            frameHeight: height,
          },
          "*"
        );
      }
    };

    const images = document.querySelectorAll("img");
    images.forEach((image) => {
      if (image.complete) {
        sendHeightToParent();
      } else {
        image.addEventListener("load", sendHeightToParent);
        image.addEventListener("error", sendHeightToParent);
      }
    });
    window.addEventListener("load", sendHeightToParent);

    sendHeightToParent();

    const resizeObserver = new ResizeObserver(sendHeightToParent);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
      images.forEach((image) => {
        image.removeEventListener("load", sendHeightToParent);
        image.removeEventListener("error", sendHeightToParent);
        window.removeEventListener("load", sendHeightToParent);
      });
    };
  }, []);

  useEffect(() => {
    const referrer = document.referrer;
    // save in cookie
    if (referrer) {
      //remove old cookie, add new one
      document.cookie = "lantern_referrer=; path=/; max-age=0; SameSite=Lax";
      document.cookie = `lantern_referrer=${referrer}; path=/; max-age=31536000; SameSite=Lax`;
    }
  }, []);

  useDetectKeyboard();

  const params = useRef(qs.parse(window.location.search?.split("?")?.[1]));
  const flowId = params.current?.flowId as string;
  const storefrontAccessToken = params.current?.storefrontAccessToken as string;
  const checkoutId = params.current?.checkoutId as string;
  const redirectUrl = params.current?.redirectUrl as string;

  const { data, error } = useQuery<ActiveFlowContainerQuery["activeFlowContainer"] | PreviewFlowContainerQuery["previewFlowContainer"] | null>(
    ["flowContainer", { slug, flowId }],
    () =>
      slug
        ? flowId
          ? sdk.previewFlowContainer({ slug, flowId }).then((res) => res.previewFlowContainer)
          : sdk.activeFlowContainer({ slug }).then((res) => res.activeFlowContainer)
        : null,
    {
      initialData: (window as any).__INITIAL_STATE__?.flowContainer,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

  const flowContainer = flowId ? (data as PreviewFlowContainerQuery["previewFlowContainer"]) : (data as ActiveFlowContainerQuery["activeFlowContainer"]);

  const number = location.pathname.match(/^\/questions\/(.*)/i)?.[1];
  const current = number ? parseInt(number, 10) : undefined;

  const flowNode = useMemo(() => flowContainer?.flow.nodes?.[typeof current !== "undefined" ? current : 0 || 0], [flowContainer, current]);

  // get sessionId from cookie lantern_${slug}_session_id
  if (flowContainer?.flow?.returnToResults) {
    const sessionId = document.cookie.match(new RegExp(`lantern_${slug}_session_id=([^;]*)`))?.[1];
    location.pathname = sessionId ? `/results/${sessionId}` : location.pathname;
  }

  useEffect(() => {
    // if the cookie lantern_session_id is present, redirect to #/results/${lantern_session_id}
    const sessionId = document.cookie.match(new RegExp(`lantern_${slug}_session_id=([^;]*)`))?.[1];
    if (sessionId && flowContainer?.flow?.returnToResults) {
      history.push(`/results/${sessionId}`);
    }
  }, []);

  if (!data && !error) {
    return null;
  }

  if (!initRef.current) {
    initRef.current = true;
    hideLoader();
  }

  if (error || !flowContainer?.flow) {
    return (
      <Box flex="1">
        <Container py="80px">
          <Title>
            <FormattedMessage {...messages.errorTitle} />
          </Title>
          <Subtext mt={2} mb={5}>
            <FormattedMessage {...messages.loadError} />
          </Subtext>

          <a href="/">
            <Button>
              <FormattedMessage {...messages.backToWebsite} />
            </Button>
          </a>
        </Container>
      </Box>
    );
  }

  const { flow } = flowContainer;

  return (
    <HeadlessProvider flowContainer={flowContainer} checkoutId={checkoutId} storefrontAccessToken={storefrontAccessToken} redirectUrl={redirectUrl}>
      <FlowProvider flow={flow} slug={slug}>
        <SessionProvider>
          <Flex flexDirection="column" flex="1">
            <Seo
              {...config.seo}
              title={flowContainer.seoTitle || flowContainer.name}
              description={flowContainer.seoDescription || undefined}
              image={flowContainer.ogImage || undefined}
            />
            <CustomCSS rawCSS={flowContainer?.flow?.cssEditor || undefined} />

            <AnimatePresence exitBeforeEnter>
              <Layout
                className="main-container"
                key={
                  flowNode &&
                  [FlowNodeLayout.MediaCoverLeft, FlowNodeLayout.MediaCoverRight].includes(flowNode.layout) &&
                  location.pathname === "/" &&
                  !isMobile
                    ? "start"
                    : "other"
                }
                hidden={location.pathname === "/" && flow?.nodes?.[0]?.type !== FlowNodeType.Welcome}
                current={current}
                exitCloseText={["/", "/results"].includes(location.pathname)}
                finishLaterRedirect={flowContainer.finishLaterRedirect ?? ""}
              >
                <ErrorBoundary
                  onReset={() => {
                    window.location.reload();
                  }}
                  fallbackRender={({ resetErrorBoundary }) => (
                    <Box py="80px">
                      <Title>
                        <FormattedMessage {...messages.errorTitle} />
                      </Title>
                      <Subtext mt={2} mb={5}>
                        <FormattedMessage {...messages.unexpectedError} />
                      </Subtext>

                      <Button onClick={() => resetErrorBoundary()}>
                        <FormattedMessage {...messages.refresh} />
                      </Button>
                    </Box>
                  )}
                >
                  <Suspense fallback={<LazyLoad />}>
                    <Router history={history} location={location} />
                  </Suspense>
                </ErrorBoundary>
              </Layout>
            </AnimatePresence>
          </Flex>
        </SessionProvider>
      </FlowProvider>
    </HeadlessProvider>
  );
};
